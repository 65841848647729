'use client';
import styles from './styles.module.css';
import VideoSlider from '../VideoSlider';
import { useRef, useState } from 'react';

const VideosPlayer = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [videos, setVideos] = useState([
    {
      index: 0,
      id: 1,
      isPlaying: false,
      source:
        '/assets/video_player/videos/bees-the-real-estate-platform-home.mp4',
      ref: useRef<HTMLVideoElement>(null),
      thumbnail: '/assets/video_player/images/slider-01-home.webp',
      icon: '/assets/icons/arrow_right.svg',
      title: 'Start Experience | Home Page',
      showDescription: true,
      description:
        'Home page designed to run in stand by mode, providing a video of the developers project that runs continuously, one click away from the start experience.',
      ariaLabel:
        'Introductory video offering a walkthrough of the real estate platform’s main features.',
    },
    {
      index: 1,
      id: 2,
      isPlaying: false,
      source: '/assets/video_player/videos/360-degree-immersive-experience.mp4',
      ref: useRef<HTMLVideoElement>(null),
      thumbnail: '/assets/video_player/images/slider-02-360immersive.webp',
      icon: '/assets/icons/360.svg',
      title: '360 Degree Immersive Experience',
      showDescription: true,
      description:
        'Step into a fully immersive experience with a seamless 360-degree view, offering unparalleled depth and interactivity at your fingertips.',
      ariaLabel:
        'Interactive 360-degree view of the future building, allowing users to rotate the structure, explore available units, and view details like pricing and other important information.',
    },
    {
      index: 2,
      id: 3,
      isPlaying: false,
      source: '/assets/video_player/videos/timeline-day-to-night.mp4',
      ref: useRef<HTMLVideoElement>(null),
      thumbnail: '/assets/video_player/images/slider-01-home.webp',
      icon: '/assets/icons/timeline.svg',
      title: 'Timeline Day to Night',
      showDescription: true,
      description:
        'Experience the passage of time firsthand with our dynamic day-to-night timeline feature, seamlessly transitioning through different lighting and conditions.',
      ariaLabel:
        'Interactive feature allowing users to view the building at different times of day, from dawn to dusk, helping them visualize how the property will look under various lighting conditions based on their preference.',
    },
    {
      index: 3,
      id: 4,
      isPlaying: false,
      source: '/assets/video_player/videos/virtual-tour.mp4',
      ref: useRef<HTMLVideoElement>(null),
      thumbnail: '/assets/video_player/images/slider-01-home.webp',
      icon: '/assets/icons/virtual_tour_icon.svg',
      title: 'Live | Virtual Tour',
      showDescription: true,
      description:
        'Embark on a real-time exploration or virtual journey through our interactive tour, bridging the gap between physical presence and digital immersion effortlessly.',
      ariaLabel:
        'Computer-generated virtual tour showing what the property will look like after construction, allowing potential buyers to explore the space in detail.',
    },
    {
      index: 4,
      id: 5,
      isPlaying: false,
      source: '/assets/video_player/videos/floor-plan-gallery-comparison.mp4',
      ref: useRef<HTMLVideoElement>(null),
      thumbnail: '/assets/video_player/images/slider-01-home.webp',
      icon: '/assets/icons/floorplan.svg',
      title: 'Floor Plan Gallery and Comparison',
      showDescription: true,
      description:
        'Compare floor plans effortlessly with our interactive gallery, designed for easy side-by-side viewing and detailed analysis.',
      ariaLabel:
        'Video comparing different floor plans side-by-side, highlighting layout features.',
    },
    {
      index: 5,
      id: 6,
      isPlaying: false,
      source: '/assets/video_player/videos/gallery-images.mp4',
      ref: useRef<HTMLVideoElement>(null),
      thumbnail: '/assets/video_player/images/slider-01-home.webp',
      icon: '/assets/icons/gallery.svg',
      title: 'Gallery Images',
      showDescription: true,
      description:
        'Explore our curated gallery of high-resolution images, showcasing every detail and perspective of the project.',
      ariaLabel:
        'Slideshow video showcasing property images, highlighting key aspects.',
    },
    {
      index: 6,
      id: 7,
      isPlaying: false,
      source: '/assets/video_player/videos/film-presentation.mp4',
      ref: useRef<HTMLVideoElement>(null),
      thumbnail: '/assets/video_player/images/slider-01-home.webp',
      icon: '/assets/icons/video.svg',
      title: 'Film Presentation',
      showDescription: true,
      description:
        'Dive into our collection of engaging videos, offering an in-depth look at the project’s features and allowing you to see the project in more detail.',
      ariaLabel:
        'Cinematic video presentation of the property, featuring detailed shots.',
    },
    {
      index: 7,
      id: 8,
      isPlaying: false,
      source: '/assets/video_player/videos/interactive-map.mp4',
      ref: useRef<HTMLVideoElement>(null),
      thumbnail: '/assets/video_player/images/slider-01-home.webp',
      icon: '/assets/icons/map.svg',
      title: 'Interactive Map',
      showDescription: true,
      description:
        'Navigate through the project with our interactive map, featuring main points of interest in the surroundings and their distances.',
      ariaLabel:
        'Video demonstrating the interactive map functionality, showing nearby points of interest.',
    },
  ]);

  const PlayOrPauseVideo = (index: number) => {
    setVideos((prevState) =>
      prevState.map((video) => {
        if (video.index === index) {
          return { ...video, isPlaying: !video.isPlaying };
        }
        return { ...video, isPlaying: false };
      })
    );
  };

  const pauseAllVideos = () => {
    setVideos((prevState) =>
      prevState.map((video) => ({ ...video, isPlaying: false }))
    );
  };

  return (
    <div className={styles.ContentDiv}>
      <VideoSlider
        videos={videos}
        PlayOrPauseVideo={PlayOrPauseVideo}
        currentSlideIndex={currentSlideIndex}
        setCurrentSlideIndex={setCurrentSlideIndex}
        pauseAllVideos={pauseAllVideos}
      />
      <div className={styles.BottomVideoContentDiv}>
        <div className={styles.PaginationDiv}>
          <div className='swiper-video-custom-pagination CustomSwiperPagination' />
        </div>
      </div>
    </div>
  );
};

export default VideosPlayer;
