export const desktopImages = [
  {
    id: '1',
    image:
      '/assets/slider_images/interactive-3d-tour-residential-building-platform.webp',
  },
  {
    id: '2',
    image:
      '/assets/slider_images/real-estate-platform-360-view-luxury-building.webp',
  },
  {
    id: '3',
    image:
      '/assets/slider_images/virtual-tour-commercial-office-space-platform.webp',
  },
  {
    id: '4',
    image: '/assets/slider_images/building-floor-plan-comparison.webp',
  },
  {
    id: '5',
    image:
      '/assets/slider_images/immersive-map-location-high-rise-development.webp',
  },
];

export const mobileImages = [
  {
    id: '1',
    image:
      '/assets/slider_images_mobile/interactive-3d-tour-residential-building-platform.webp',
  },
  {
    id: '2',
    image:
      '/assets/slider_images_mobile/real-estate-platform-360-view-luxury-building.webp',
  },
  {
    id: '3',
    image:
      '/assets/slider_images_mobile/virtual-tour-commercial-office-space-platform.webp',
  },
  {
    id: '4',
    image: '/assets/slider_images_mobile/building-floor-plan-comparison.webp',
  },
  {
    id: '5',
    image:
      '/assets/slider_images_mobile/immersive-map-location-high-rise-development.webp',
  },
  {
    id: '6',
    image: '/assets/slider_images_mobile/bees-real-estate-platform.webp',
  },
];
