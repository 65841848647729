import styles from './styles.module.css';
import ScheduleDemoForm from './components/ScheduleDemoForm';
import useWindowSize from '../../../../hooks/useWindowSize';

const FormSection = () => {
  const { width: screenWidth } = useWindowSize();

  return (
    <div className={styles.ContainerWrapper}>
      <video
        autoPlay
        muted
        loop
        playsInline
        webkit-playsinline='true'
        className={styles.VideoBackground}
      >
        <source
          src='/assets/videos/bees-the-real-estate-platform-home-hexagons.mp4'
          type='video/mp4'
        />
      </video>
      <div className={styles.Container} id='schedule_demo_container'>
        <div className={styles.SectionContentDiv}>
          <div className={styles.InformationDiv}>
            <div className={styles.InformationDivHeader}>
              <img
                src={'/assets/icons/bees_b_logo.svg'}
                alt={'bees'}
                width={50}
                height={56}
              />
              <div style={{ marginTop: 14, marginBottom: 20 }}>
                {screenWidth >= 760 ? (
                  <>
                    <h1 className={styles.InformationDivTitle}>Experience</h1>
                    <h1 className={styles.InformationDivTitle}>
                      the Real Estate Platform
                    </h1>
                  </>
                ) : (
                  <>
                    <h1 className={styles.InformationDivTitle}>Experience</h1>
                    <h1 className={styles.InformationDivTitle}>
                      the Real Estate
                    </h1>
                    <h1 className={styles.InformationDivTitle}>Platform</h1>
                  </>
                )}
              </div>
              <p className={styles.InformationDivDescription}>
                To visionaries, trailblazers, and innovators, Bees extends an
                invitation. Let’s co-create a future without bounds, where
                innovation flourishes and real estate projects shine.
              </p>
            </div>
            {screenWidth >= 760 && (
              <div className={styles.InformationDivBotom}>
                <div className={styles.EmailDiv}>
                  <img
                    src={'/assets/icons/email_icon.svg'}
                    alt={'email'}
                    width={16}
                    height={16}
                  />
                  <p className={styles.EmailText}>info@go-bees.com</p>
                </div>
              </div>
            )}
          </div>
          <ScheduleDemoForm />
        </div>
      </div>
    </div>
  );
};

export default FormSection;
