import styled from 'styled-components';

export const ContainerWrapper = styled.div`
  z-index: 2;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  width: 100vw;
  height: 100dvh;
  background-image: url('/assets/images/real-estate-platform-homepage-immersive-experience.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
